import useSWR from "swr";
import { useState, useEffect } from "react";
import { getMerchantUrl } from "api/services/auth.service";
import { getFormattedAmount } from "utils/lib/number-formatter";


export default function useMerchant(config) {
   const [accountDetails, setAccountDetails] = useState("");
   const [merchantId, setMerchantId] = useState("");
   const [ledgerBalance, setLedgerBalance] = useState("");

   const {
      data: merchant,
      error: getMerchantError,
      mutate,
   } = useSWR(getMerchantUrl, config);

   useEffect(() => {
      if (merchant?.bank_details) {
         const { account_number: accountNumber, account_name: accountName } =
            merchant.bank_details;
         setAccountDetails(`${accountName}, ${accountNumber}`);
         setMerchantId(merchant.unique_id);
         setLedgerBalance(getFormattedAmount(merchant.meta.balance));
      }
   }, [merchant]);

   const getMerchantLoading = !merchant && !getMerchantError;
   return {
      merchantId,
      merchant,
      ledgerBalance,
      accountDetails,
      getMerchantError,
      getMerchantLoading,
      mutate,
   };
}
