import TableCol from "components/shared/tables/table-col";
import TableRow from "components/shared/tables/table-row";
import { MdCallMade } from "react-icons/md";
import { BsBoxArrowInDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getFormattedAmount } from "utils/lib/number-formatter";
import { getFormattedDate } from "utils/lib/number-formatter";

export const FallbackComponent = ({ transactionType }) => {
   return (
      <div className="text-sm text-secondary">
         your {transactionType} history will appear here once you have completed{" "}
         {transactionType} transactions
      </div>
   );
};

export default function PaymentHistory({
   className,
   transactionType,
   transactionData,
}) {
   //styles
   const wrapper = `${className} w-5/6 mx-auto mb-10 flex flex-col md:px-5 sm:text-xl lg:text-base`;
   const h2 = "text-xl text-gray-500 font-semibold";
   const h3 = "text-md text-gray-400 font-semibold";
   const accountNumber =
      "self-start text-gray-600 font-semibold text-sm sm:text-base md:text-lg lg:text-xl";
   const bankName = "self-start text-gray-400 text-xs sm:text-sm";
   const date =
      "justify-center text-gray-400 text-xs sm:text-sm md:text-base lg:text-xs";
   const type = "justify-center text-gray-400";
   const amount = `justify-end font-semibold ${
      transactionType === "payout" ? "text-red-500" : "text-green-500"
   }`;
   const link =
      "self-end text-yellow-500 underline text-xs cursor-pointer hover:text-yellow-400 font-semibold mt-4 sm:text-sm lg:text-xs";

   return (
      <section className={wrapper}>
         <h2 className={h2}>
            {transactionType === "payout"
               ? "payout history"
               : "transfer history"}
         </h2>
         <h3 className={h3}>recent transactions</h3>
         {transactionData?.length < 1 ? (
            <FallbackComponent transactionType={transactionType} />
         ) : (
            <div>
               {transactionData?.slice(0, 4).map((i) => (
                  <TableRow key={transactionData._id}>
                     <TableCol className="flex-col">
                        <p className={accountNumber}>
                           {i.transaction_type === "transfer"
                              ? i.from
                              : i.transaction_type === "payout"
                              ? i?.paid_to?.accountNumber
                              : ""}
                        </p>
                        <p className={bankName}>
                           {i.transaction_type === "transfer"
                              ? i.to
                              : i.transaction_type === "payout"
                              ? i?.paid_to?.bankName
                              : ""}
                        </p>
                     </TableCol>
                     <TableCol className={date}>
                        {getFormattedDate(i.created)}
                     </TableCol>
                     <TableCol className={type}>
                        {i.transaction_type === "payout" ? (
                           <MdCallMade />
                        ) : i.transaction_type === "transfer" ? (
                           <BsBoxArrowInDown />
                        ) : (
                           ""
                        )}
                     </TableCol>
                     <TableCol className={amount}>
                        {getFormattedAmount(i.amount)}
                     </TableCol>
                  </TableRow>
               ))}
               <Link to="/dashboard/transactions" className={link}>
                  see full list &gt;{" "}
               </Link>
            </div>
         )}
      </section>
   );
}
