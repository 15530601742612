export default function SummaryTab({ value, valuePct, label }) {
   const getColor = (property, value) => {
      switch (label) {
         case "total transfers": {
            return `${property}-green-${value}`;
         }
         case "total payouts": {
            return `${property}-red-${value}`;
         }
         default: {
            return `${property}-blue-${value}`;
         }
      }
   };

   const textClass = `${getColor("text", "500")} text-right`;

   const bgClass = `${getColor("bg", "300")} 
      col-span-2 h-2 rounded-lg sm:h-3 md:h4`;

   const pctClass = `${getColor("bg", "500")} 
      h-full rounded-lg transition-all duration-500`;

   return (
      <div className="mb-6 grid grid-cols-2">
         <p>{label}</p>
         <p className={textClass}>{value}</p>
         <div className={bgClass}>
            <div style={{ width: valuePct }} className={pctClass}></div>
         </div>
      </div>
   );
}
