import { useState } from "react";

import Button from "components/shared/forms/button";
import { useFormData } from "utils/hooks/useFormData";
import RequestsTable from "components/dashboard/requests/table";
import Header from "components/shared/layout/headers/dashboard-header";
import RangeModal from "components/shared/modals/range-modal";
import RequestPayoutModal from "components/dashboard/requests/request-payout";
import useFormValidation from "utils/hooks/useFormValidation";
import useAllRequests from "api/hooks/useAllRequests";

export default function Requests() {
   const initialFormData = {
      startDate: "",
      endDate: "",
   };
   const [state, dispatch] = useFormData(initialFormData);
   const { formIsValid } = useFormValidation(state);

   const filterOptions = ["processing", "approved", "declined"];

   const [rangeModal, setRangeModal] = useState(false);
   const [requestModal, setRequestModal] = useState(false);
   const toggleRangeModal = () => setRangeModal((prev) => !prev);
   const toggleRequestModal = () => setRequestModal((prev) => !prev);

   const {
      requests: requestsData,
      requestsError,
      requestsLoading,
      mutateRequests,
   } = useAllRequests();

   const handleFilterSelect = (option) => {
      dispatch({
         type: "updateInput",
         payload: { name: "selectedFilter", value: option },
      });
   };

   const handleDateChange = (name, value) => {
      dispatch({
         type: "updateInput",
         payload: { name, value },
      });
   };

   const handleFilter = () => {
      if (formIsValid) {
         console.log("filtered");
      }
   };

   return (
      <>
         <section className="wrapper">
            <Header
               className="mb-2 pt-6 md:border-none md:bg-white md:text-slate-500"
               title="requests"
               schoolId="ICE325"
               settingsUrl="../settings"
            />
            <RequestsTable
               requestsData={requestsData}
               requestsError={requestsError}
               mutateRequests={mutateRequests}
               requestsLoading={requestsLoading}
            />
            <div className="fixed bottom-[77px] flex w-full flex-col items-center bg-white p-6 md:bottom-0 md:w-[calc(100%-128px)] lg:w-[calc(100%-192px)]">
               <Button onClick={toggleRequestModal}>request payout</Button>
            </div>
         </section>

         <RangeModal
            toggleModal={toggleRangeModal}
            modalDisplay={rangeModal}
            dispatch={dispatch}
            options={filterOptions}
            startDate={state.startDate}
            endDate={state.endDate}
            selectedFilter={state.selectedFilter}
            handleSubmit={handleFilter}
            handleDateChange={handleDateChange}
            handleRangeSelect={handleFilterSelect}
         />
         <RequestPayoutModal
            mutateAllRequests={mutateRequests}
            modalDisplay={requestModal}
            toggleModal={toggleRequestModal}
         />
      </>
   );
}
